import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('theme', ['getHandWritings'])
  },

  methods: {
    ...mapMutations('theme', ['setHandWriting']),

    handwritingSet (key) {
      this.setHandWriting({ key, value: false })
    },

    getHandwritingVisible (key) {
      return this.getHandWritings[key]
    }
  }
}
