var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"get-suggestions"},[_c('h3',{staticClass:"font-outfit-medium"},[_vm._v(" "+_vm._s(_vm.$t(_vm.headings[_vm.type]))+" ")]),_c('p',{staticClass:"add-margin ai-get-suggestion-description"},[_vm._v(" "+_vm._s(_vm.$t('aiBuddy.getSuggestions.adjustingYourBusinessDescription'))+" "),_c('router-link',{attrs:{"to":{name: 'idea-settings'}}},[_vm._v(" "+_vm._s(_vm.$t('pages.ideaSettings.title'))+" ")])],1),_c('div',{staticClass:"suggestions-container"},[(_vm.loading)?_c('div',{staticClass:"generating font-outfit-medium"},[_c('IbBlinkingAnimationSentences',{attrs:{"sentences":[
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence01'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence02'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence03'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence04'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence05'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence06'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence07'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence08')
      ]}})],1):_vm._e(),(_vm.parser)?[_c(_vm.parser,{tag:"component",attrs:{"loading":_vm.loading,"suggestions":_vm.suggestions},on:{"click":_vm.onClickSuggestion},model:{value:(_vm.selectedSuggestion),callback:function ($$v) {_vm.selectedSuggestion=$$v},expression:"selectedSuggestion"}})]:[(!_vm.loading && _vm.suggestions.length)?_c('div',_vm._l((_vm.suggestions),function(suggestion,index){return _c('ai-suggestion-tag',{key:index,ref:`pillSuggestion${index}`,refInFor:true,staticClass:"mr-2",attrs:{"selected":_vm.isSuggestionSelected(suggestion)},on:{"click":function($event){return _vm.onClickTag(index)}}},[_vm._v(" "+_vm._s(suggestion)+" ")])}),1):_vm._e()]],2),_c('div',{staticClass:"d-flex justify-content-start"},[_c('p',{staticClass:"disclaimer-text font-outfit-light"},[_vm._v(" "+_vm._s(_vm.$t('aiDisclaimer'))+" ")])]),_c('ib-divider',{staticClass:"my-3",attrs:{"block":""}}),_c('div',{staticClass:"footer"},[_c('ai-buddy-request-remaining'),_c('div',{staticClass:"buttons"},[_c('ib-button-basic',{staticClass:"my-3 my-md-0",attrs:{"is-active":"","icon":"ib-icon-refresh","disabled":_vm.loading},on:{"click":_vm.generate}},[_c('span',{staticClass:"font-outfit-regular"},[_vm._v(_vm._s(_vm.$t('aiBuddy.regenerate')))])]),_c('ib-button-basic',{staticClass:"ml-md-2",attrs:{"color":"gray","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticClass:"font-outfit-regular"},[_vm._v(_vm._s(_vm.$t('aiBuddy.close')))])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }