<template>
  <v-app>
    <v-col class="d-flex pa-0 justify-content-end">
      <v-select
        v-model="period"
        class="switcher-container"
        outlined
        dense
        :items="[
          {
            text:$t('monthly'),
            value: 'monthly',
          },
          {
            text:$t('quarterly'),
            value: 'quarterly',
          },
          {
            text:$t('yearly'),
            value: 'yearly',
          }
        ]"
        @change="$emit('change', period)"
      />
    </v-col>
  </v-app>
</template>

<script>
export default {
  name: 'TablePeriodSwitcher',

  data () {
    return {
      period: 'monthly'
    }
  }
}
</script>

<style scoped lang="scss">
.switcher-container {
  min-width: 138px;
  max-width: 138px;
}
</style>
