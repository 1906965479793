<template>
  <ib-expand-panel
    class="mt-1 mt-md-0"
    :value="visible"
    title="Info"
    icon="ib-icon-info-3"
    @click="$emit('click', $event)"
  >
    <div class="info-guide-add-dialog-content-wrapper">
      <!-- Guide Header -->
      <h3 class="m-0 font-outfit-medium">
        {{ title }}
      </h3>
      <!-- /Guide Header -->

      <!-- Text -->
      <p class="info-guide-add-dialog" v-html="text" />
      <!-- /Text -->

      <ib-divider block class="my-3" />
      <div class="d-flex justify-content-end">
        <ib-button-basic
          color="gray"
          @click="$emit('click')"
        >
          <span>{{ $t('gotIt') }}</span>
        </ib-button-basic>
      </div>
    </div>
  </ib-expand-panel>
</template>

<script>
export default {
  name: 'InfoGuideAddDialog',

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.info-guide-add-dialog-content-wrapper {

  h3 {
    font-size: 18px;
  }

  .info-guide-add-dialog {
    ::v-deep {
      font-size: 15px;
      line-height: 20px;

      .text-top {
        display: block;
        margin-bottom: 15px;
      }

      .bullet:last-of-type {
        margin-bottom: 15px;
      }

      .bullet {
        position: relative;
        visibility: visible;
        display: block;
        padding-left: 30px;
        line-height: 2;

        &:before {
          content: "•";
          position: absolute;
          left: 15px;
          top: -8px;
          font-size: 22px;
        }
      }
    }
  }
}
</style>
