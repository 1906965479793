import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('theme', ['getAiBuddyButtonAnimationsShow'])
  },

  methods: {
    ...mapMutations('theme', ['setAiBuddyButtonAnimationShow']),

    hideAiBuddyBuddyAnimation (key) {
      this.setAiBuddyButtonAnimationShow({ key: key, value: false })
    }
  }
}
