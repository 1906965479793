<template>
  <ib-expand-panel
    :value="visible"
    title="Info"
    :expandable="false"
    icon="ib-icon-info-3"
    @click="$emit('click', $event)"
  >
    <div v-if="guideExample" class="info-guide-content-wrapper">
      <!-- Image -->
      <v-img
        class="content-img img-rounded"
        :src="getImgUrl()"
        contain
        min-height="250"
      />
      <!-- /Image -->

      <!-- Title -->
      <h3 class="font-outfit-medium text-left mb-3 mt-0">
        {{ guideExample.title }}
      </h3>
      <!-- /Title -->

      <!-- Text -->
      <p class="info-guide-text m-0 text-left" v-html="guideExample.text" />
      <!-- /Text -->
      <ib-divider block class="my-3 fix-mess" />
      <div class="d-flex justify-content-end fix-mess">
        <ib-button-basic
          color="gray"
          @click="$emit('click')"
        >
          <span class="font-outfit-light">{{ $t('aiBuddy.close') }}</span>
        </ib-button-basic>
      </div>
    </div>

  </ib-expand-panel>

</template>

<script>
import { mapGetters } from 'vuex'

import defaultGuideExampleImages from '@/data/defaultGuideExampleImages.json'

export default {
  name: 'InfoGuide',

  props: {
    visible: {
      default: false,
      type: Boolean
    },
    type: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      defaultGuideExampleImages
    }
  },

  computed: {
    ...mapGetters('idea', ['getGuideExample']),

    guideExample () {
      return this.getGuideExample(this.type)
    }
  },

  methods: {
    getImgUrl () {
      if (this.guideExample && this.guideExample.image) {
        return `${this.guideExample.image.path}${this.guideExample.image.filename}`
      }

      return require(`@/assets/img/illustrations/guide-examples/${this.defaultGuideExampleImages[this.type]}`)
    }
  }
}
</script>

<style scoped lang="scss">
.info-guide-content-wrapper {
  display: flow-root;
  text-align: center;

  @include media-breakpoint-up($md) {
    text-align: left;
  }

  h3 {
    font-size: 18px;
  }

  .content-img {
    width: 90%;
    margin: 0 auto 20px;

    @include media-breakpoint-up($md) {
      float: right;
      width: 30%;
      margin: 10px 20px 20px;
    }
  }

  .img-rounded {
    border-radius: 0.75rem;
  }

  .info-guide-text {
    ::v-deep {
      font-size: 15px;
      line-height: 20px;

      .bullet {
        position: relative;
        visibility: visible;
        display: block;
        padding-left: 30px;
        line-height: 2;

        &:before {
          content: '•';
          position: absolute;
          left: 15px;
          top: -8px;
          font-size: 22px;
        }
      }
    }
  }

  .fix-mess {
    clear: both;
  }
}
</style>
