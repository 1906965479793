import i18n from '@/i18n'

export default {
  data () {
    return {
      steps: {
        concept: {
          enabled: true,
          name: 'concept',
          title: i18n.t('steps.concept.title'),
          description: i18n.t('steps.concept.description'),
          icon: require('@/assets/img/business-guide/icon-concept.svg'),
          items: [
            {
              name: 'idea',
              title: i18n.t('steps.concept.items.idea'),
              completedStepName: 'ideaCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-idea',
              editVisible: false,
              paid: false,
              startHere: true
            },
            {
              name: 'product',
              title: i18n.t('steps.concept.items.product'),
              completedStepName: 'productCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-shipping-product',
              editVisible: false,
              paid: false,
              startHere: false
            },
            {
              name: 'promotion',
              title: i18n.t('steps.concept.items.promotion'),
              completedStepName: 'promotionCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-commercial',
              editVisible: false,
              paid: false,
              startHere: false
            },
            {
              name: 'distribution',
              title: i18n.t('steps.concept.items.distribution'),
              completedStepName: 'distributionCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-in-transit',
              editVisible: false,
              paid: false,
              startHere: false
            },
            {
              name: 'partners',
              title: i18n.t('steps.concept.items.partners'),
              completedStepName: 'partnerCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-user-account',
              editVisible: false,
              paid: false,
              startHere: false
            }
            // {
            //   name: 'testing',
            //   title: 'Testing',
            //   completedStepName: 'testingCompleted',
            //   completedStatus: true,
            //   enabled: false,
            //   beta: false,
            //   icon: 'ib-icon-pass-fail'
            // }
          ]
        },
        research: {
          enabled: true,
          name: 'research',
          title: i18n.t('steps.research.title'),
          description: i18n.t('steps.research.description'),
          icon: require('@/assets/img/business-guide/icon-research.svg'),
          items: [
            {
              name: 'market',
              title: i18n.t('steps.research.items.market'),
              completedStepName: 'marketCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-basket',
              editVisible: false,
              paid: false,
              startHere: false
            },
            {
              name: 'customers',
              title: i18n.t('steps.research.items.customers'),
              completedStepName: 'customersCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-client-management',
              editVisible: false,
              paid: false,
              startHere: false
            },
            {
              name: 'competitors',
              title: i18n.t('steps.research.items.competitors'),
              completedStepName: 'competitorsCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-conference',
              editVisible: false,
              paid: false,
              startHere: false
            },
            // {
            //   name: 'comparison',
            //   title: 'Comparison',
            //   completedStepName: 'comparisonCompleted',
            //   completedStatus: true,
            //   enabled: false,
            //   beta: false,
            //   icon: 'ib-icon-compare'
            // },
            {
              name: 'swot-analysis',
              title: i18n.t('steps.research.items.swotAnalysis'),
              completedStepName: 'swotAnalysisCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-grid-2',
              editVisible: false,
              paid: false,
              startHere: false
            },
            {
              name: 'market-potential',
              title: i18n.t('steps.research.items.marketPotential'),
              completedStepName: 'marketPotentialCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-account',
              editVisible: false,
              paid: false,
              startHere: false
            }
          ]
        },
        setup: {
          enabled: true,
          name: 'set-up',
          title: i18n.t('steps.setUp.title'),
          description: i18n.t('steps.setUp.description'),
          icon: require('@/assets/img/business-guide/icon-setup.svg'),
          items: [
            {
              name: 'startup-costs',
              title: i18n.t('steps.setUp.items.startupCosts'),
              completedStepName: 'startupCostsCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-money-bag',
              editVisible: false,
              paid: false,
              startHere: false
            },
            {
              name: 'financing',
              title: i18n.t('steps.setUp.items.financing'),
              completedStepName: 'financingCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-money',
              editVisible: false,
              paid: false,
              startHere: false
            },
            {
              name: 'management-team',
              title: i18n.t('steps.setUp.items.managementTeam'),
              completedStepName: 'managementTeamCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-meeting-room',
              editVisible: false,
              paid: false,
              startHere: false
            },
            {
              name: 'staff-and-payroll',
              title: i18n.t('steps.setUp.items.staffAndPayroll'),
              completedStepName: 'employeesAndPayrollCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-money-transfer',
              editVisible: false,
              paid: false,
              startHere: false
            },
            {
              name: 'ownership',
              title: i18n.t('steps.setUp.items.ownership'),
              completedStepName: 'ownershipCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-investment-portfolio',
              editVisible: false,
              paid: false,
              startHere: false
            }
            // {
            //   name: 'checklist',
            //   title: 'Checklist',
            //   completedStepName: 'checklistCompleted',
            //   completedStatus: true,
            //   enabled: false,
            //   beta: false,
            //   icon: 'ib-icon-to-do'
            // }
          ]
        },
        projections: {
          enabled: true,
          name: 'projections',
          title: i18n.t('steps.projections.title'),
          description: i18n.t('steps.projections.description'),
          icon: require('@/assets/img/business-guide/icon-projections.svg'),
          items: [
            {
              name: 'revenue-streams',
              title: i18n.t('steps.projections.items.revenueStreams'),
              completedStepName: 'revenueStreamsCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-get-revenue',
              editVisible: false,
              paid: false,
              startHere: false
            },
            {
              name: 'direct-costs',
              title: i18n.t('steps.projections.items.directCosts'),
              completedStepName: 'directCostsCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-cost',
              editVisible: false,
              paid: true,
              startHere: false
            },
            {
              name: 'marketing-budget',
              title: i18n.t('steps.projections.items.marketingBudget'),
              completedStepName: 'marketingBudgetCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-transaction',
              editVisible: false,
              paid: true,
              startHere: false
            },
            {
              name: 'other-overheads',
              title: i18n.t('steps.projections.items.otherOverheads'),
              completedStepName: 'otherOverheadsCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-estimate',
              editVisible: false,
              paid: true,
              startHere: false
            },
            {
              name: 'profit-and-cash-flow',
              title: i18n.t('steps.projections.items.profitAndCashFlow'),
              completedStepName: 'profitAndCashFlowCompleted',
              completedStatus: true,
              enabled: true,
              beta: false,
              icon: 'ib-icon-currency-exchange',
              editVisible: false,
              paid: false,
              startHere: false
            }
            // {
            //   name: 'industry-standards',
            //   title: 'Industry Stand.',
            //   completedStepName: 'industryStandardsCompleted',
            //   completedStatus: true,
            //   enabled: false,
            //   beta: false,
            //   icon: 'ib-icon-spiral-bound-booklet'
            // }
          ]
        }
        // validation: {
        //   enabled: true,
        //   name: 'validation',
        //   title: i18n.t('steps.validation.title'),
        //   description: i18n.t('steps.validation.description'),
        //   items: [
        //     {
        //       name: 'idea-score',
        //       title: i18n.t('steps.validation.items.ideaScore'),
        //       completedStepName: 'ideaScoreCompleted',
        //       completedStatus: false,
        //       enabled: true,
        //       beta: true,
        //       icon: 'ib-icon-rating'
        //     }
        //   ]
        // }
      }
    }
  }
}
