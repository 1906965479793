<template>
  <button
    :disabled="disabled"
    :style="{ height: height }"
    class="ib-button-generating d-flex justify-content-center align-items-center disabled"
    :class="classes"
    @click="onClick"
  >
    <template v-if="loading">
      <ib-spinner :color="color" />
    </template>
    <template v-else>
      <slot />
    </template>
  </button>
</template>

<script>

import IbSpinner from '@/components/_v2/IbSpinner.vue'

/* Colors */
const COLORS = ['violet', 'blue', 'yellow', 'red']
export default {
  name: 'IbButtonGenerating',
  components: { IbSpinner },

  props: {
    color: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: 'violet'
    },
    height: {
      type: String,
      default: '36px'
    },
    icon: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    animation: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return [
        this.colorClass,
        { icon: this.icon },
        { disabled: this.disabled, 'ai-buddy-animation-pulse-button': this.animation }
      ]
    },

    colorClass () {
      return `ib-button-generating-${this.color}`
    }
  },

  methods: {
    onClick () {
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">

   .ib-button-generating {
     padding: 0 28px;
     border-radius: 8px;
     transition: all 0.25s;
     cursor: pointer;

     &.ib-button-generating-violet {
       background: $color-ai-light;
       border: 2px solid $color-ai-light;
       color: $color-ai;

       &:hover {
         border: 2px solid $color-ai;
       }
     }

     &.ib-button-generating-blue {
       background: $color-primary-light;
       border: 2px solid $color-primary-light;
       color: $color-primary;

       &:hover {
         border: 2px solid $color-primary;
       }
     }

     &.ib-button-generating-yellow {
       background: $color-yellow-light;
       border: 2px solid $color-yellow-light;
       color: $color-yellow;

       &:hover {
         border: 2px solid $color-yellow;
       }
     }

     &.ib-button-generating-red {
       background: $color-red-super-light;
       border: 2px solid $color-red-super-light;
       color: $color-red-light;

       &:hover {
         border: 2px solid $color-red-light;
       }
     }

     i {
       font-size: 22px;
       margin-right: 10px;
     }

     span {
       font-size: 17px;
     }
   }

   button:disabled {
     cursor: not-allowed;
     opacity: .6;
     transition: all 0s;

     &:hover {
       border: none !important;
     }
   }

</style>
