export default {
  methods: {
    scrollToTop () {
      document.querySelectorAll('[role="dialog"]')
        .forEach(dialog => dialog.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        }))
    }
  }
}
