<template>
  <div class="get-suggestions">
    <h3 class="font-outfit-medium">
      {{ $t(headings[type]) }}
    </h3>

    <p class="add-margin ai-get-suggestion-description">
      {{ $t('aiBuddy.getSuggestions.adjustingYourBusinessDescription') }}
      <router-link :to="{name: 'idea-settings'}">
        {{ $t('pages.ideaSettings.title') }}
      </router-link>
    </p>

    <div class="suggestions-container">
      <div v-if="loading" class="generating font-outfit-medium">
        <IbBlinkingAnimationSentences :sentences="[
          $t('aiBuddy.getSuggestions.sentences.sentence01'),
          $t('aiBuddy.getSuggestions.sentences.sentence02'),
          $t('aiBuddy.getSuggestions.sentences.sentence03'),
          $t('aiBuddy.getSuggestions.sentences.sentence04'),
          $t('aiBuddy.getSuggestions.sentences.sentence05'),
          $t('aiBuddy.getSuggestions.sentences.sentence06'),
          $t('aiBuddy.getSuggestions.sentences.sentence07'),
          $t('aiBuddy.getSuggestions.sentences.sentence08')
        ]"
        />
      </div>

      <template v-if="parser">
        <component
          :is="parser"
          v-model="selectedSuggestion"
          :loading="loading"
          :suggestions="suggestions"
          @click="onClickSuggestion"
        />
      </template>
      <template v-else>
        <div v-if="!loading && suggestions.length">
          <ai-suggestion-tag
            v-for="(suggestion, index) in suggestions"
            :key="index"
            :ref="`pillSuggestion${index}`"
            class="mr-2"
            :selected="isSuggestionSelected(suggestion)"
            @click="onClickTag(index)"
          >
            {{ suggestion }}
          </ai-suggestion-tag>
        </div>
      </template>
    </div>

    <div class="d-flex justify-content-start">
      <p class="disclaimer-text font-outfit-light">
        {{ $t('aiDisclaimer') }}
      </p>
    </div>

    <ib-divider block class="my-3" />

    <div class="footer">
      <ai-buddy-request-remaining />
      <div class="buttons">
        <ib-button-basic
          class="my-3 my-md-0"
          is-active
          icon="ib-icon-refresh"
          :disabled="loading"
          @click="generate"
        >
          <span class="font-outfit-regular">{{ $t('aiBuddy.regenerate') }}</span>
        </ib-button-basic>
        <ib-button-basic
          class="ml-md-2"
          color="gray"
          :disabled="loading"
          @click="$emit('close')"
        >
          <span class="font-outfit-regular">{{ $t('aiBuddy.close') }}</span>
        </ib-button-basic>
      </div>
    </div>
  </div>
</template>

<script>
import AiSuggestionTag from '@/views/Home/StoryMode/Components/AiBuddy/AiSuggestionTag.vue'
import MixinAiRequests from '@/mixins/permissions/aiRequests'
import { aiPillAnimation } from '@/helpers/animations'
import aiSuggestionHeadings from '@/data/aiSuggestionHeadings'

import { mapActions, mapGetters } from 'vuex'
import AiBuddyRequestRemaining from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyRequestRemaining.vue'
import AiSuggestionBasicParser from '@/views/Home/StoryMode/Components/AiBuddy/Suggestions/AiSuggestionBasicParser.vue'
import AiSuggestionCompetitorParser from '@/views/Home/StoryMode/Components/AiBuddy/Suggestions/AiSuggestionCompetitorParser.vue'
import AiSuggestionDirectCostParser from '@/views/Home/StoryMode/Components/AiBuddy/Suggestions/AiSuggestionDirectCostParser.vue'
import AiSuggestionMarketingCostParser from '@/views/Home/StoryMode/Components/AiBuddy/Suggestions/AiSuggestionMarketingCostParser.vue'
import AiSuggestionOtherOverheadParser from '@/views/Home/StoryMode/Components/AiBuddy/Suggestions/AiSuggestionOtherOverheadParser.vue'
import AiSuggestionPayrollExpenseParser from '@/views/Home/StoryMode/Components/AiBuddy/Suggestions/AiSuggestionPayrollExpenseParser.vue'
import AiSuggestionProductParser from '@/views/Home/StoryMode/Components/AiBuddy/Suggestions/AiSuggestionProductParser.vue'
import AiSuggestionStartupCostParser from '@/views/Home/StoryMode/Components/AiBuddy/Suggestions/AiSuggestionStartupCostParser.vue'
import IbBlinkingAnimationSentences from '@/components/_v2/IbBlinkingAnimation/IbBlinkingAnimationSentences.vue'

export default {
  name: 'AiGetSuggestions',

  components: {
    IbBlinkingAnimationSentences,
    AiSuggestionBasicParser,
    AiBuddyRequestRemaining,
    AiSuggestionTag,
    AiSuggestionProductParser,
    AiSuggestionCompetitorParser,
    AiSuggestionPayrollExpenseParser,
    AiSuggestionStartupCostParser,
    AiSuggestionMarketingCostParser,
    AiSuggestionOtherOverheadParser,
    AiSuggestionDirectCostParser
  },

  mixins: [MixinAiRequests],

  inject: ['visible'],

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      loading: false,
      firstTime: true,
      suggestions: [],
      selectedSuggestion: null
    }
  },

  computed: {
    ...mapGetters('user', ['getAiRequests']),

    parser () {
      const parsers = {
        distribution: 'AiSuggestionBasicParser',
        partners: 'AiSuggestionBasicParser',
        product: 'AiSuggestionProductParser',
        promotion: 'AiSuggestionBasicParser',
        competitors: 'AiSuggestionCompetitorParser',
        customers: 'AiSuggestionBasicParser',
        'staff-and-payroll': 'AiSuggestionPayrollExpenseParser',
        'startup-costs': 'AiSuggestionStartupCostParser',
        'marketing-costs': 'AiSuggestionMarketingCostParser',
        'other-overheads': 'AiSuggestionOtherOverheadParser',
        'direct-costs': 'AiSuggestionDirectCostParser',
        'revenue-streams': 'AiSuggestionBasicParser'
      }

      return parsers[this.type] ? parsers[this.type] : null
    },

    isVisible () {
      return this.visible()
    },

    headings () {
      return aiSuggestionHeadings
    }
  },

  watch: {
    async isVisible (newValue) {
      if (newValue && this.firstTime) {
        await this.generate()
        this.firstTime = false
      }
    }
  },

  async mounted () {
    if (this.isVisible) {
      await this.generate()
    }
  },

  methods: {
    ...mapActions('user', ['fetchAiRequests']),

    async generate () {
      this.suggestions = []
      this.selectedSuggestion = null
      try {
        this.loading = true
        const response = await this.$http.get(`ai/${this.type}/suggestions/${this.$store.state.idea.id}`)

        if (response.data.payload.data.suggestions.suggestions) {
          this.suggestions = response.data.payload.data.suggestions.suggestions
        } else {
          this.suggestions = response.data.payload.data.suggestions
        }

        await this.fetchAiRequests()
        const { amount, amountSpent } = this.getAiRequests

        this.$intercom.update({
          AICredit: amount,
          AIUsed: amountSpent
        })

        if (this.suggestions) {
          setTimeout(() => {
            this.suggestions.forEach((item, index) => {
              const element = this.$refs[`pillSuggestion${index}`]
              if (element) {
                aiPillAnimation(element[0].$el, index)
              }
            })
          }, 1)
        }

        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    onClickSuggestion (suggestion) {
      this.$emit('click', suggestion)

      this.$notify({
        title: `${this.$t('success')}`,
        message: `${this.$t('suggestionAdded')}`,
        type: 'success'
      })
    },

    onClickTag (index) {
      const suggestion = this.suggestions[index]

      this.$emit('click', suggestion)

      if (this.isSuggestionSelected(suggestion)) {
        this.selectedSuggestion = null
      } else {
        this.selectedSuggestion = suggestion
        this.copyToClipboard(suggestion)
      }
    },

    copyToClipboard (value) {
      navigator.clipboard.writeText(value)
      this.$notify.closeAll()
      this.$notify({
        title: `${this.$t('success')}`,
        message: `${this.$t('suggestionAdded')}`,
        type: 'success'
      })
    },

    isSuggestionSelected (suggestion) {
      return this.selectedSuggestion === suggestion
    }
  }
}
</script>

<style scoped lang="scss">
.get-suggestions {
  font-size: 16px;

  h3 {
    font-size: 18px;
    margin: 0;
  }

  .add-margin {
    margin: 20px 0;
  }

  .ai-get-suggestion-description {
    font-size: 15px;
  }

  .disclaimer-text {
    font-size: 14px;
    opacity: .7;
    margin: 5px 0 3px;
  }

  .suggestions-container {
    min-height: 150px;
    background: #FAFBFF;
    border: .8px solid #C4C4C4;
    border-radius: 6px;
    padding: 12px;
  }

  .generating {
    margin: 0;
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% { opacity: 0; }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up($md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include media-breakpoint-up($md) {
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}
</style>
