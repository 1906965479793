<template>
  <IbBlinkingAnimation :text="sentences[currentSentence]" />
</template>

<script>
import IbBlinkingAnimation from '@/components/_v2/IbBlinkingAnimation/IbBlinkingAnimation.vue'
import { gsap } from 'gsap'

export default {
  name: 'IbBlinkingAnimationSentences',

  components: { IbBlinkingAnimation },

  props: {
    sentences: {
      type: Array,
      required: true
    },

    changeInterval: {
      type: Number,
      default: 3000
    }
  },

  data () {
    return {
      currentSentence: 0,
      intervalId: null,
      tl: null
    }
  },

  mounted () {
    this.tl = gsap.timeline({ repeat: -1, repeatDelay: 0.5 })
    this.startInterval()
  },

  beforeDestroy () {
    clearInterval(this.intervalId)
    this.tl.kill()
  },

  methods: {
    startInterval () {
      this.intervalId = setInterval(() => {
        this.currentSentence = (this.currentSentence + 1) % this.sentences.length
      }, this.changeInterval)
    }
  }
}
</script>
