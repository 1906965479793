<template>
  <div
    class="ai-handwriting d-none d-xl-flex"
    :class="{ 'down': directionDown }"
  >
    <ib-handwriting :text="$t('clickToGenerate')" :reverse="reverse" tag="div">
      <template #arrow>
        <img
          :src="require('@/assets/img/util/arrow-up-left.svg')"
          width="40"
          alt="arrow"
          class="arrow-handwriting"
        >
      </template>
    </ib-handwriting>
  </div>
</template>

<script>
import IbHandwriting from '@/components/_v2/IbHandwriting.vue'

export default {
  name: 'AddDialogAiHandwriting',

  components: { IbHandwriting },

  props: {
    directionDown: {
      type: Boolean,
      default: false
    },

    reverse: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.ai-handwriting {
  position: absolute;
  left: 200px;

  .arrow-handwriting {
    margin-bottom: 8px;
  }

  ::v-deep {
    .ib-handwriting-text {
      margin-top: 15px !important;
      font-size: 18px;

      @include media-breakpoint-up($lg) {
        font-size: 22px;
      }
    }
  }

  &.down {
    left: unset;

    .arrow-handwriting {
      transform: scale(-1, -1);
      margin-bottom: 0;
      margin-top: 20px;
    }

    ::v-deep {
      .ib-handwriting-text {
        margin-top: 0 !important;
      }
    }
  }

  img {
    margin: 0;
  }
}
</style>
