<template>
  <div class="ib-loading-text">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'IbBlinkingAnimation',

  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.ib-loading-text {
  margin: 0;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
