var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ai-help-me-write-generated-content-wrapper"},[_c('div',{staticClass:"text-and-btn d-flex flex-column flex-md-row align-center"},[_c('span',{staticClass:"text font-outfit-medium"},[_vm._v(_vm._s(_vm.$t('aiGeneratedContent')))]),(!_vm.inputContent)?_c('ib-button-basic',{attrs:{"height":"36px","disabled":_vm.loading || _vm.generating},on:{"click":_vm.autoWrite}},[_c('span',{staticClass:"font-outfit-medium"},[_vm._v(_vm._s(_vm.$t('autoWrite')))])]):_vm._e(),(_vm.inputContent)?_c('ai-help-me-write-dropdown',{attrs:{"disabled":_vm.loading || _vm.generating},on:{"change":_vm.onChange},model:{value:(_vm.selectedMethod),callback:function ($$v) {_vm.selectedMethod=$$v},expression:"selectedMethod"}}):_vm._e()],1),_c('Transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"generate-content"},[(_vm.loading)?_c('IbBlinkingAnimationSentences',{staticClass:"ib-blinking-animation-sentences",attrs:{"sentences":[
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence01'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence02'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence03'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence04'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence05'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence06'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence07'),
        _vm.$t('aiBuddy.getSuggestions.sentences.sentence08')
      ]}}):_vm._e(),(_vm.responseText && !_vm.stopGenerating)?_c('vue-typed-js',{ref:"typedRef",attrs:{"strings":[_vm.responseText],"content-type":'html',"shuffle":false,"start-delay":10,"type-speed":1,"show-cursor":false},on:{"onStart":function($event){_vm.generating = true},"onComplete":function($event){_vm.generating = false}}},[_c('p',{staticClass:"typing ma-0"})]):_vm._e(),(_vm.responseText && _vm.stopGenerating)?_c('p',{staticClass:"typing ma-0",domProps:{"innerHTML":_vm._s(_vm.responseText)}}):_vm._e(),(!_vm.loading)?_c('ib-divider',{staticClass:"mb-3 mt-0",attrs:{"block":""}}):_vm._e(),(!_vm.responseText && !_vm.loading)?_c('p',{staticClass:"generated-content-will-appear-here"},[_vm._v(" "+_vm._s(_vm.$t('generatedContentWillAppearHere'))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-center justify-md-end"},[(_vm.generating)?_c('div',{staticClass:"stop-generating-button-wrapper"},[_c('ib-button-basic',{attrs:{"icon":"ib-icon-stop"},on:{"click":_vm.stop}},[_vm._v(" "+_vm._s(_vm.$t('stop'))+" ")])],1):_vm._e(),(!_vm.generating && _vm.responseText)?[_c('div',{staticClass:"d-flex flex-column flex-md-row generating-loading-buttons-wrapper"},[_c('ib-button-basic',{attrs:{"filled":"","icon":"ib-icon-swap"},on:{"click":_vm.replaceContent}},[_vm._v(" "+_vm._s(_vm.inputContent ? _vm.$t('replaceContent') : _vm.$t('insertContent'))+" ")]),_c('ib-button-basic',{attrs:{"icon":"ib-icon-refresh"},on:{"click":function($event){return _vm.callApi(_vm.selectedMethod)}}},[_vm._v(" "+_vm._s(_vm.$t('regenerate'))+" ")])],1)]:_vm._e()],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }