<template>
  <div class="help-me-write">

    <div>
      <div class="d-flex flex-column">
        <h3 class="font-outfit-medium">
          {{ infoTexts.title || $t('yourContent') }}
        </h3>
        <p class="help-me-write-description">
          {{ $t('aiBuddyHelpsYouWrite') }}
        </p>
      </div>
      <el-row :gutter="50">
        <el-col :xl="12">
          <div class="mb-5">
            <jodit-editor
              v-model="content"
              :config="joditEditorConfig"
              :buttons="joditEditorButtons"
            />
            <div class="d-flex flex-row justify-content-between jodit-counter-wrapper">
              <p class="disclaimer-text font-outfit-light">
                {{ contentLength }} / {{ charactersLimit }}
              </p>
              <p class="disclaimer-text font-outfit-light">
                {{ $t('dragToExpand') }}
              </p>
            </div>
          </div>
        </el-col>
        <el-col :xl="12">
          <div class="handwriting-wrapper relative">
            <ai-help-me-write-generated-content
              :type="type"
              @replaceContent="content = $event"
            />
            <AddDialogAiHandwriting v-if="getHandwritingVisible('aiHelpMeWrite')" direction-down :reverse="false" class="generate-handwriting" />
          </div>
          <div class="d-flex justify-content-start">
            <p class="disclaimer-text font-outfit-light">
              {{ $t('aiDisclaimer') }}
            </p>
          </div>
        </el-col>
      </el-row>
    </div>

    <div>
      <v-divider />

      <div class="d-flex flex-column flex-md-row justify-content-end align-items-center help-me-write-footer">
        <ai-buddy-request-remaining />
        <div class="d-flex flex-row">
          <ib-button-basic
            class="mr-3"
            @click="save"
          >
            <span class="font-outfit-regular">{{ $t('save') }}</span>
          </ib-button-basic>
          <ib-button-basic
            color="gray"
            @click="$emit('close')"
          >
            <span class="font-outfit-regular">{{ $t('discard') }}</span>
          </ib-button-basic>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddDialogAiHandwriting from '@/views/Home/Components/AddDialogAiHandwriting.vue'
import AiBuddyRequestRemaining from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyRequestRemaining.vue'
import AiHelpMeWriteGeneratedContent from '@/views/Home/StoryMode/Components/AiBuddy/AiHelpMeWriteGeneratedContent.vue'
import EventBus from '@/event-bus'
import { Jodit } from 'jodit'
import { JoditEditor } from 'jodit-vue'
import MixinHandwriting from '@/mixins/handwriting'
import { mapActions } from 'vuex'

export default {
  name: 'AiHelpMeWrite',

  components: {
    AddDialogAiHandwriting,
    AiBuddyRequestRemaining,
    AiHelpMeWriteGeneratedContent,
    JoditEditor
  },
  mixins: [
    MixinHandwriting
  ],

  provide () {
    return {
      content: () => this.content
    }
  },

  inject: ['getSection', 'getVisible'],

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      content: null
    }
  },

  computed: {
    visible () {
      return this.getVisible()
    },

    section () {
      return this.getSection()
    },

    infoTexts () {
      return this.$t(`businessPlanAdditionalInfoTexts.${this.section.sectionName.toLowerCase()}`)
    },

    joditEditorConfig () {
      return {
        height: 250,
        // disabled: !this.canEdit || this.disabled,
        toolbar: true,
        toolbarButtonSize: 'middle',
        inline: false,
        showPlaceholder: true,
        placeholder: this.$t('enterYourInputOrContent'),
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        limitChars: 10000,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        controls: {
          paragraph: {
            list: Jodit.atom({
              h1: 'Heading 1',
              h2: 'Heading 2',
              h3: 'Heading 3',
              p: 'Paragraph',
              blockquote: 'Quote'
            })
          }
        },
        disablePlugins: ['addNewLine']
      }
    },

    joditEditorButtons () {
      return [
        'bold',
        'italic',
        'underline',
        // 'strikethrough',
        // 'eraser',
        'paragraph',
        // 'superscript',
        // 'subscript',
        'hr',
        'ul',
        'ol'
        // 'brush',
        // 'link',
        // 'table'
      ]
    },

    contentLength () {
      return this.content.replace(/(<([^>]+)>)/ig, '').length
    },

    charactersLimit () {
      return 10000
    }
  },

  watch: {
    visible: {
      handler (value) {
        if (value) {
          this.content = this.section.subTitle
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('idea', ['updateBusinessPlanAdditionalInfo']),

    save () {
      this.updateBusinessPlanAdditionalInfo({ id: this.section.id, content: this.content })
      EventBus.$emit('ai-help-me-write-save', this.content)
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.help-me-write {
  height: 88.3vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    color: $color-navy;
    font-family: $outfit-regular;
    font-size: 20px;
    margin: 0;
    line-height: 38px;
  }

  .help-me-write-description {
    color: $color-navy;
    font-size: 16px;
    font-weight: 300;
    //opacity: .5;
    margin: 0 0 28px;
  }

  .handwriting-wrapper {
    position: relative;

    .generate-handwriting {
      position: absolute;
      top: -65px;
      left: 50px;
    }
  }
}

.help-me-write-footer {
  row-gap: 10px;
}

.disclaimer-text {
  color: $color-navy;
  font-size: 14px;
  opacity: .6;
  margin: 5px 0 3px;
  line-height: 14px;
}

.jodit-counter-wrapper {
  margin-top: 10px;

  p {
    margin: 5px 0 3px;
  }
}
</style>
